import html2canvas from "html2canvas";
import gsap from "gsap";
import ScrollTrigger from "../utils/ScrollTrigger.min";
import Score from "./Score";
import CookieUtil from "../utils/Cookie";

export default class Drawing {
  constructor(canvasId, scroll, lenis) {
    this.server = location.protocol + '//' + location.host + location.pathname;
    this.canvas = $(`#${canvasId}`)[0];
    this.context = this.canvas.getContext('2d');
    this.isDrawing = false;
    this.lenis = lenis;
    this.isFix = false;
    this.scaleFactor = 3; // Facteur d'échelle

    this.titles = {
      climat: [
        "le train du futur",
        "une éolienne en mer"
      ],
      nature: [
        "un nichoir",
        "un mur végétal"
      ],
      numerique: [
        "l'usine du futur",
        "un avion à hydrogène"
      ],
      ville: [
        "un pont",
        "le tram du futur"
      ]
    };

    this.init();
    this.initToggle();
    this.initEvents();
  }

  init() {
    this.setSize();
    this.context.strokeStyle = 'yellow';
    this.context.lineWidth = 5;
    this.context.lineCap = 'round';

    let $context = this;
    window.addEventListener('popstate', function(event) {
      $context.enabledScroll();
    });
  }

  setSize() {
    const wrapper = this.canvas.parentElement;
    this.canvas.width = wrapper.offsetWidth * this.scaleFactor;
    this.canvas.height = wrapper.offsetHeight * this.scaleFactor;
    this.canvas.style.width = `${wrapper.offsetWidth}px`;
    this.canvas.style.height = `${wrapper.offsetHeight}px`;
    this.context.scale(this.scaleFactor, this.scaleFactor);
    this.context.strokeStyle = 'yellow';
    this.context.lineWidth = 5;
    this.context.lineCap = 'round';
  }

  initEvents() {
    const canvasElement = $(`#${this.canvas.id}`);
    canvasElement.on('mousedown touchstart', (e) => this.startDrawing(e));
    canvasElement.on('mouseup touchend', () => this.stopDrawing());
    canvasElement.on('mouseout touchcancel', () => this.stopDrawing());
    canvasElement.on('mousemove touchmove', (e) => this.draw(e));
    //window.addEventListener('resize', () => this.setSize());
  }

  startDrawing(event) {
    this.isDrawing = true;
    this.context.beginPath();
    const { offsetX, offsetY } = this.getEventPosition(event);
    this.context.moveTo(offsetX, offsetY);
    event.preventDefault();
  }

  stopDrawing() {
    this.isDrawing = false;
    this.context.beginPath();
  }

  draw(event) {
    if (!this.isDrawing) return;

    const { offsetX, offsetY } = this.getEventPosition(event);
    this.context.lineTo(offsetX, offsetY);
    this.context.stroke();
    event.preventDefault();
  }

  getEventPosition(event) {
    const rect = this.canvas.getBoundingClientRect();
    if (event.type.startsWith('touch')) {
      const touch = event.touches[0] || event.changedTouches[0];
      return {
        offsetX: (touch.clientX - rect.left) * (this.canvas.width / rect.width) / this.scaleFactor,
        offsetY: (touch.clientY - rect.top) * (this.canvas.height / rect.height) / this.scaleFactor,
      };
    } else {
      return {
        offsetX: (event.clientX - rect.left) * (this.canvas.width / rect.width) / this.scaleFactor,
        offsetY: (event.clientY - rect.top) * (this.canvas.height / rect.height) / this.scaleFactor,
      };
    }
  }

  initToggle() {
    let $context = this;
    let lenis = this.lenis;

    $('[data-action="drawing-card"]').click(function () {
      $('[data-action="drawing-card"]').removeClass('active');
      $('[data-action="screenshot"]').removeClass('load');
      $(this).addClass('active');
    });

    $('[data-action="drawing-card"]').click(function () {
      let active = $(this);
      let parent = $(active.data('target'));
      let bgEl = parent.find(active.data('target') + '__bg');
      if (bgEl) {
        bgEl.attr('src', active.attr('src'))
      }
      const category = active.data('category');
      $context.updateTitle(category);
      parent.addClass(category);

      parent.addClass('active');
      $('html, body').animate({
        scrollTop: $('#dessin').offset().top
      }, 500);
      let pause = setTimeout(function () {
        parent.addClass('is-inview');
        $context.disabledScroll();
        $context.context.clearRect(0, 0, $context.canvas.width, $context.canvas.height);
        clearTimeout(pause);
      }, 1);

      $(parent).find('.wrapper-dessin__inner__back').click(function () {
        parent.removeClass('is-inview active');
        active.removeClass('active');
        $context.enabledScroll();
      });

      //si ils commencent le jeu, ils doivent le finir
      CookieUtil.set('gameLaunched', '#futur-metier', 7);
    });

    $('[data-action="screenshot"]').on('click', function (event) {
      event.preventDefault();

      const category = $('[data-action="drawing-card"].active').data('category');
      $(this).addClass('load');

      const drawingElement = document.querySelector('#dessin .wrapper-dessin__inner');

      Promise.all([
        $context.ensureImagesLoaded(),
        document.fonts.ready, // Optionnel si les polices doivent être chargées
      ]).then(() => {
        html2canvas(drawingElement, {
          scale: 2,
          useCORS: true,
          ignoreElements: function (element) {
            return element.classList.contains('group-btn') ||
              element.classList.contains('wrapper-dessin__inner__back');
          }
        }).then(canvas => {
          const dataURL = canvas.toDataURL('image/png');

          // Envoi de l'image capturée à un serveur pour la stocker
          $context.uploadScreenshot(dataURL, category).then(response => {
            if (response.success) {
              $(`.section4.${category}`).addClass("active");
              lenis.start();
              document.querySelector("header.header").style.display = "none";

              gsap.to("body", {
                autoAlpha: 0,
                duration: .44,
                onComplete: () => {
                  $("body").addClass("ending");
                  document.querySelector("footer").style.display = "block";
                  gsap.to("body", {
                    autoAlpha: 1,
                    duration: .44,
                    onStart: () => {
                      $('html, body').animate({
                        scrollTop: 0
                      }, 0)
                    }
                  })
                }
              })

              ScrollTrigger.refresh();
              let scoreBlock = new Score(document.querySelector(".section4.active"), lenis, '#dessine-univers');
              lenis.start();
            } else {
              alert('Erreur lors de la capture d\'écran.');
            }
          }).catch(error => {
            alert('Erreur lors de la validation. Veuillez recharger la page et recommencer.');
            console.error('Erreur lors de l\'upload de la capture d\'écran :', error);
          });
        });
      }).catch((error) => {
        alert('Erreur lors de la validation. Veuillez recharger la page et recommencer.');
        console.error("Erreur lors du chargement des ressources :", error);
      });
    });
  }

  uploadScreenshot(dataURL, category) {
    let server = this.server;
    return new Promise((resolve, reject) => {
      $.ajax({
        url: server + 'screenshot/add', // L'URL de votre endpoint de téléchargement
        method: 'POST',
        data: {
          image: dataURL,
          category: category
        },
        success: function (response) {
          resolve(response);
        },
        error: function (error) {
          reject(error);
        }
      });
    });
  }

  ensureImagesLoaded() {
    const images = Array.from(document.querySelectorAll('img'));
    images.forEach((img) => {
      if (img.loading === 'lazy') {
        img.loading = 'eager'; // Force le chargement immédiat
      }
    });

    return Promise.all(
      images.map((img) => {
        if (img.complete && img.naturalHeight !== 0) {
          return Promise.resolve(); // Image déjà chargée
        }
        return new Promise((resolve, reject) => {
          img.onload = resolve;
          img.onerror = reject;
        });
      })
    );
  }

  getTitles(category) {
    return this.titles[category] || [];
  }

  updateTitle(category) {
    const possibleTitles = this.getTitles(category);
    if (possibleTitles.length > 0) {
      const randomIndex = Math.floor(Math.random() * possibleTitles.length);
      const randomTitle = possibleTitles[randomIndex];
      $('.wrapper-dessin .title > span').text(randomTitle);

      const sanitizedTitle = this.sanitizeTitle(randomTitle);
      const newUrl = `${window.location.origin}${window.location.pathname}?carte=${sanitizedTitle}`;
      window.history.pushState({ path: newUrl }, '', newUrl);
    }
  }
  sanitizeTitle(title) {
    title = title.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    return title.toLowerCase().replace(/[^a-z0-9]+/g, '-').replace(/^-+|-+$/g, '');
  }

  disabledScroll() {
    let $context = this;
    if (!$("html").hasClass("overflow-tinder") && !$context.isFix) {
      $context.isFix = true;
      $('html, body').animate({
        scrollTop: $("#drawingCanvas").offset().top
      }, 0, 'swing', function () {
        $("html").addClass("overflow-tinder")
        $context.lenis.stop();
      });
    }
  }

  enabledScroll() {
    let $context = this;
    $("html").removeClass("overflow-tinder")
    $context.lenis.start();
    $context.isFix = false;
  }
}
